export default {
  heading: 'Rajdhani, sans-serif',
  body: 'Kadwa',
  monospace: 'Menlo, monospace',
  display: 'Monoton, sans-serif',
  display2: 'Tangerine, serif',
  googleFonts: [
    'Rajdhani:300,400,500,600,700',
    'Kadwa:400,700',
    'Monoton',
    'Tangerine',
  ],
  // customFamilies: [''],
  // customUrls: [''],
}
