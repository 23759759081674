export default {
  menuV1: {
    container: {
      padding: ['3rem 0.5rem', '3rem 1rem'],
      justifyContent: 'center',
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },

    backToMenuBtn: {
      variant: 'buttons.primary',
    },

    imageFill: {
      paddingBottom: '100%',
    },

    // Tabs

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px',
      },
    },

    // ================================
    // =========  ALL IN  =============
    // ================================

    allInContainer: {},
    menuContainer: { padding: '1rem 0rem' },

    // ================================
    // =========  CELLS  ==============
    // ================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
      margin: '2rem 0rem',
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))', 'calc(33.3% - (0.5rem * 2))'],
      backgroundColor: 'primary',
      color: 'light',
      margin: ['1rem 0', '0.5rem'],
    },

    menuCell: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'solid 1px black',
      position: 'relative',
      // height: '100%',
      // backgroundColor: 'background',
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cellName: {
      margin: '0rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      position: 'absolute',
      bottom: '0rem',
      color: 'white',
      backgroundColor: ' #00000075',
      right: '0rem',
      padding: '0rem 1rem',
    },

    // ================================
    // ========  Menu Sections ========
    // ================================

    menuSectionTitle: {
      fontSize: '2rem',
      textAlign: ['center'],
      margin: ['0.5rem 0.5rem 1rem', '1rem 1rem 1rem', '1rem 1.75rem 1rem'],
      textTransform: 'uppercase',
      color: 'primary',
      fontWeight: 'normal',
      fontFamily: 'display',
    },
    menuSectionDescription: {
      textAlign: 'center',
      fontSize: 4,
      margin: ['0rem 0.5rem 2rem', '0rem 1rem 2rem', '0rem 1.75rem 2rem'],
      marginBottom: '1rem',
      opacity: '0.8',
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0,
    },

    // ================================
    // ========  Menu Items ===========
    // ================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem', '', '0 1rem'],
      marginBottom: '1rem',
      margin: ['', '0.5rem'],
      width: ['100%'],
    },
    menuItemContainerImgActive: {
      variant: 'menuV1.menuItemContainer',
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
    },
    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px'],
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      padding: '0 0.75rem 0 0',
      cursor: 'pointer',
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none',
    },
    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '0rem',
    },
    menuItemName: {
      fontSize: ['1rem', '1.1rem', '', '1.3rem'],
      fontWeight: 'bolder',
      margin: '0px',
      paddingRight: '8px',
      fontFamily: 'heading',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    menuItemDescription: {
      fontSize: '1rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      fontFamily: 'heading',
      opacity: '0.8',
      lineHeight: '1.5',
    },

    // ================================
    // ======  Menu Items Price =======
    // ================================

    menuPriceContainer: {
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end',
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      fontWeight: 'bold',
    },

    variantsContainer: {
      justifyContent: 'center',
    },

    labelTitle: {
      display: 'flex',
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex',
      justifyContent: 'center',
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px',
    },
    menuItemPriceVariants: {},

    // ================================
    // ======= Section Dropdown =======
    // ================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '0.75rem 1.75rem',
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0 2.5%',
      display: 'flex',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center',
      },
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline',
        },
      },
    },
  },
}
